import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';

function App() {
  const [FormComponent, setFormComponent] = useState(null);

  useEffect(() => {
    const fetchCountryAndSetForm = async () => {
      try {
        const { data } = await axios.get('https://ipapi.co/json/');
        const country = data.country_code;
        const spanishSpeakingCountries = ['ES', 'MX', 'AR', 'CL', 'PE', 'VE', 'CO', 'BO', 'CU', 'DO', 'EC', 'GT', 'HN', 'NI', 'PA', 'PY', 'PE', 'PR', 'UY', 'SV'];

        if (spanishSpeakingCountries.includes(country)) {
          const { default: SearchFormES } = await import('./components/SearchFormES');
          setFormComponent(<SearchFormES />);
        } else {
          const { default: SearchForm } = await import('./components/SearchForm');
          setFormComponent(<SearchForm />);
        }
      } catch (error) {
        console.error('Error determining the user country:', error);
        import('./components/SearchForm').then(({ default: SearchForm }) => {
          setFormComponent(<SearchForm />);
        });
      }
    };

    fetchCountryAndSetForm();
  }, []);

  // Directly return null or a minimal/non-intrusive placeholder while the correct form is being determined and loaded
  if (!FormComponent) return null;

  return (
    <div className="App">
      {FormComponent}
    </div>
  );
}

export default App;
